<template>
  <div>
    <loading v-if="vLoading" />
    <div class="new-letter pb-6">
      <v-card class="letter-card">
        <v-row>
          <v-col class="pb-0">
            <div class="mb-8 grey--text text--darken-2">
              <h5>نامه سازمانی جدید</h5>
            </div></v-col
          >
        </v-row>
        <!--receiver and sender for new part -->
        <v-row>
          <v-col v-if="!letterDetails.sender">
            <v-btn
              class="w-100 blue-mainBtn"
              height="40"
              @click="openSelectUserDialog('sender')"
              ><v-icon class="white-icon px-2">$AddPerson</v-icon> انتخاب
              فرستنده</v-btn
            >
          </v-col>
          <v-col v-else class="mt-1 d-flex justify-space-between">
            <div class="mt-2">
              <span class="grey-color">فرستنده: </span>
              <span class="h6 font-weight-bold dark-grey-color">
                {{ letterDetails.sender.name }}</span
              >
            </div>

            <v-btn
              class="float-end blue-borderedBtn"
              @click="openSelectUserDialog('sender')"
            >
              <v-icon class="px-1 blue-icon">$AddPerson</v-icon>
              تغییر</v-btn
            >
          </v-col>
          <v-col v-if="!letterDetails.selectedReceivers.length">
            <v-btn
              class="w-100 blue-mainBtn"
              height="40"
              @click="openSelectUserDialog('receiver')"
              ><v-icon class="white-icon px-2">$AddPerson</v-icon> انتخاب
              گیرنده</v-btn
            >
          </v-col>
          <v-col v-else class="mt-1 d-flex justify-space-between">
            <div class="mt-2 dark-grey-color">
              <span class="grey-color">گیرنده:</span>

              <span
                class="h6 font-weight-bold dark-grey-color"
                v-if="letterDetails.selectedReceivers.length == 1"
              >
                {{ letterDetails.selectedReceivers[0].name }}
              </span>

              <span v-else class="ps-1 font-weight-bold">
                <v-tooltip max-width="300" dark bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <b v-bind="attrs" v-on="on">
                      {{ letterDetails.selectedReceivers[0].name }} و
                      {{ letterDetails.selectedReceivers.length - 1 }} نفر دیگر
                    </b>
                  </template>
                  <span class="fs-xsmall">
                    {{
                      letterDetails.selectedReceivers
                        .map((x) => x.name)
                        .join(" , ")
                    }}
                  </span>
                </v-tooltip>
              </span>
            </div>

            <v-btn
              class="float-end blue-borderedBtn"
              @click="openSelectUserDialog('receiver')"
            >
              <v-icon class="px-1 blue-icon">$AddPerson</v-icon>
              تغییر</v-btn
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="!letterDetails.date">
            <div class="custom-input-blue">
              <div class="d-flex flex-row">
                <v-text-field
                  outlined
                  dense
                  height="40"
                  v-model="letterDetails.date"
                  id="letterDate"
                >
                  <template slot="label">
                    <div style="font-size: 14px">
                      <span class="red--text">*</span>
                      تاریخ نامه
                    </div>
                  </template>
                </v-text-field>

                <v-btn
                  class="ms-2 blue-borderedBtn"
                  id="letterDate"
                  width="40"
                  min-width="40"
                  height="40"
                  ><v-icon>$Calendar</v-icon></v-btn
                >
                <date-picker
                  element="letterDate"
                  v-model="letterDetails.date"
                  color="#6092cd"
                ></date-picker>
              </div>
            </div>
          </v-col>
          <v-col class="mt-1 d-flex justify-space-between" v-else>
            <div class="mt-2 common-text">
              <span class="grey-color">تاریخ نامه: </span
              ><span class="font-weight-bold dark-grey-color">{{
                letterDetails.date
              }}</span>
            </div>
            <div>
              <v-btn class="float-end blue-borderedBtn" id="letterDate2">
                <v-icon class="px-1 blue-icon">$Calendar</v-icon>
                تغییر</v-btn
              >
              <date-picker
                element="letterDate2"
                v-model="letterDetails.date"
                color="#6092cd"
              ></date-picker>
            </div>
          </v-col>

          <v-col>
            <div class="custom-input-blue">
              <div class="d-flex flex-row">
                <v-select
                  outlined
                  v-model="letterDetails.assigneId"
                  :items="selectEmployees"
                  height="40"
                  dense
                  item-text="text"
                  item-value="id"
                >
                  <template slot="label">
                    <div style="font-size: 14px">تخصیص به</div>
                  </template>
                  <template slot="append">
                    <v-icon
                      @click="letterDetails.assigneId = ''"
                      v-if="letterDetails.assigneId"
                      >mdi-close</v-icon
                    >
                    <v-icon>mdi-menu-down</v-icon>
                  </template>
                </v-select>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="letter-card mt-4">
        <v-row>
          <v-col>
            <div class="custom-input-blue">
              <div class="d-flex flex-row">
                <v-text-field
                  outlined
                  dense
                  height="40"
                  v-model="letterDetails.title"
                  clearable
                >
                  <template slot="label">
                    <div style="font-size: 14px">
                      <span class="red--text">*</span>
                      موضوع نامه
                    </div>
                  </template>
                </v-text-field>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="custom-input-blue">
              <div class="d-flex flex-row">
                <v-select
                  outlined
                  :items="templates"
                  dense
                  height="40"
                  v-model="letterDetails.templateId"
                  item-text="name"
                  item-value="id"
                  @input="
                    letterDetails.templateId
                      ? getTemplateInfo(letterDetails.templateId)
                      : ''
                  "
                >
                  <template slot="label">
                    <div style="font-size: 14px">قالب نامه</div>
                  </template>
                </v-select>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-parent">
              <div
                class="blue-ckeditor"
                :style="
                  templateLoading && letterDetails.templateId
                    ? 'opacity: 0.7;pointer-events: none;'
                    : ''
                "
              >
                <vue-ckeditor v-model="letterDetails.text" :config="config" />
              </div>
              <div
                v-if="templateLoading && letterDetails.templateId"
                class="text-center text-loading"
              >
                <v-progress-circular indeterminate color="#6092cd">
                </v-progress-circular>
                <div color="#4C81C9" class="mt-4">
                  <span>در حال بارگذاری قالب...</span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="letter-card mt-4">
        <v-row>
          <v-col>
            <h6 class="grey-color">فایل پیوست</h6>
            <v-row>
              <v-col cols="12" md="12">
                <div
                  class="blue-uploadBox"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="dropFile"
                >
                  <p class="mb-2 mt-1">فایل پیوست را به داخل کادر بکشید</p>
                  <p class="mb-2">یا</p>
                  <v-btn
                    class="blue-mainBtn"
                    height="40"
                    width="150"
                    @click="$refs.uploadFile.$refs.input.click()"
                    >آپلود فایل</v-btn
                  >
                </div>
                <v-file-input
                  @change="uploadFile(file)"
                  v-model="file"
                  ref="uploadFile"
                  outlined
                  dense
                  v-show="false"
                ></v-file-input>
              </v-col>
              <v-col>
                <div v-if="fileUploading" class="w-100">
                  <div class="blue-upload-div">
                    <div class="d-flex flex-row w-100">
                      <div>
                        <v-icon class="mt-2 blue-icon" large>$File</v-icon>
                      </div>
                      <div class="w-100 px-3">
                        <p class="mb-0">
                          {{
                            showName.length > 40
                              ? showName.slice(0, 40) + "..."
                              : showName
                          }}
                        </p>
                        <v-progress-linear
                          class="mt-1"
                          outlined
                          height="4"
                          :value="myFile.uploadPercentage"
                          color="#4C81C9"
                        >
                        </v-progress-linear>
                      </div>
                    </div>
                  </div></div
              ></v-col>
            </v-row>
            <div>
              <div v-if="letterDetails.files">
                <div v-if="letterDetails.files.length" class="w-100 mt-4">
                  <span class="grey-color">
                    تعداد فایل‌های انتخاب شده:
                    {{ letterDetails.files.length }} عدد</span
                  >
                  <v-row class="mt-1">
                    <v-col
                      v-for="(file, index) in letterDetails.files"
                      :key="index"
                      md="6"
                      cols="12"
                    >
                      <div class="blue-upload-div">
                        <div class="d-flex flex-row w-100">
                          <div>
                            <v-icon class="mt-2 blue-icon" large>$File</v-icon>
                          </div>
                          <div class="w-100 px-3">
                            <p class="mb-0 mt-3">
                              {{
                                file.name.length > 25
                                  ? file.name.slice(0, 25) + "..."
                                  : file.name
                              }}
                            </p>
                          </div>
                          <div
                            @click="download(file.url, file.name)"
                            class="pointer mt-2"
                          >
                            <v-icon class="blue-icon">$Download</v-icon>
                          </div>
                          <div
                            class="ms-2 mt-2 pointer"
                            @click="removeFile(index)"
                          >
                            <v-icon class="blue-icon">$Trash</v-icon>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="letter-card mt-4">
        <v-row>
          <v-col>
            <v-row
              ><v-col> <h6 class="grey-color">انتخاب تگ</h6> </v-col
              ><v-col>
                <v-btn
                  class="float-end blue-mainBtn"
                  height="40"
                  @click="chooseTagDialog = true"
                >
                  افزودن تگ
                </v-btn></v-col
              ></v-row
            >
            <v-row v-if="!letterDetails.tags.length">
              <v-col>
                <p class="grey-color text-center">
                  این نامه در حال حاضر تگی ندارد.
                </p>

                <p class="mb-2 grey-color text-center">
                  برای افزودن تگ از طریق دکمه‌ی افزودن تگ اقدام کنید.
                </p>
              </v-col>
            </v-row>

            <v-row v-if="letterDetails.tags.length">
              <v-col class="pt-0">
                <v-row class="tag-area me-1">
                  <v-col class="d-flex flex-wrap">
                    <div
                      v-for="(item, index) in letterDetails.tags"
                      :key="index"
                      class="me-1 blue-badge"
                    >
                      <span>{{ item }}</span>

                      <span
                        ><v-icon
                          @click="removeTag('letterDetails', index)"
                          color="#4C81C9"
                          small
                          class="ps-1"
                          >mdi-close</v-icon
                        ></span
                      >
                    </div></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="letter-card mt-4">
        <v-row>
          <v-col>
            <v-row>
              <v-col> <h6 class="grey-color">نامه‌های مرتبط</h6> </v-col
              ><v-col>
                <v-btn
                  class="float-end blue-mainBtn"
                  height="40"
                  @click="
                    addLetterDialog = true;
                    currentPage = 1;
                  "
                >
                  افزودن نامه مرتبط
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div v-if="!letterDetails.relatedLetters.length" class="my-2">
                  <p class="mb-2 grey-color text-center">
                    این نامه در حال حاضر با هیچ نامه‌ای مرتبط نیست.
                  </p>
                  <p class="mb-2 grey-color text-center">
                    برای افزودن نامه مرتبط از طریق دکمه‌ی افزودن نامه مرتبط
                    اقدام کنید.
                  </p>
                </div>
                <div v-else class="mt-6">
                  <b-table
                    responsive
                    show-empty
                    stacked="sm"
                    :items="letterDetails.relatedLetters"
                    :fields="fields"
                    empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    striped
                    thead-class="headClass"
                    tbody-class="blue-trClass
                    "
                    :busy="isBusy"
                    :per-page="perPageLetter"
                    :current-page="currentPageLetter"
                  >
                    <template v-slot:cell(index)="data">
                      <div
                        style="text-align: center; vertical-align: middle"
                        class="pt-0"
                      >
                        {{
                          data.index +
                          1 +
                          perPageLetter * (currentPageLetter - 1)
                        }}
                      </div>
                    </template>
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div
                        style="text-align: center; vertical-align: middle"
                        class="pt-0"
                      >
                        {{ data.value }}
                      </div>
                    </template>

                    <template v-slot:cell(operation)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-btn
                          @click="removeLetters(data.index)"
                          height="36"
                          width="80"
                          class="red-mainBtn pa-3"
                          >حذف</v-btn
                        >
                      </div>
                    </template>
                    <div slot="table-busy" class="text-center text-danger my-2">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                  <div
                    v-if="letterDetails.relatedLetters.length"
                    class="d-flex flex-row justify-content-end blue-custom-pagination"
                  >
                    <v-pagination
                      v-model="currentPageLetter"
                      :length="
                        Math.ceil(
                          letterDetails.relatedLetters.length / perPageLetter
                        )
                      "
                      :total-visible="5"
                      prev-icon="$ArrowLeft"
                      next-icon="$ArrowRight"
                    ></v-pagination>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="letter-card mt-4">
        <v-row>
          <v-col>
            <div class="custom-input-blue">
              <div class="d-flex flex-row">
                <v-text-field
                  outlined
                  dense
                  height="40"
                  v-model="letterDetails.followingDate"
                  id="followingDate"
                >
                  <template slot="label">
                    <div style="font-size: 14px">تاریخ پیگیری بعدی</div>
                  </template>
                </v-text-field>

                <v-btn
                  class="ms-2 blue-borderedBtn"
                  id="followingDate"
                  width="40"
                  min-width="40"
                  height="40"
                  ><v-icon>$Calendar</v-icon></v-btn
                >
                <date-picker
                  element="followingDate"
                  v-model="letterDetails.followingDate"
                  :min="currentDate"
                  color="#4C81C9"
                >
                </date-picker>
              </div>
            </div>
          </v-col>
          <v-col> </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="blue-mainBtn"
              width="150"
              height="40"
              :disabled="
                !letterDetails.title ||
                !letterDetails.date ||
                !letterDetails.text ||
                computedSenderReceiver
              "
              @click="submitLetter('sent')"
            >
              <span>ارسال نامه</span>
            </v-btn>
            <v-btn
              class="ms-5 blue-borderedBtn"
              height="40"
              @click="submitLetter('draft')"
              >ذخیره پیش‌نویس</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              @click="letterResultDialog = true"
              class="float-end blue-borderedBtn"
              height="40"
              :disabled="
                !letterDetails.title ||
                !letterDetails.date ||
                !letterDetails.text ||
                computedSenderReceiver
              "
              >پیش‌نمایش
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-dialog v-model="choosePersonDialog" width="600">
      <v-card class="dash-modal-card" style="overflow-y: auto !important">
        <v-row>
          <v-col class="pt-4">
            <h5 class="mt-1 grey-color">
              {{ userType == "sender" ? "انتخاب فرستنده" : "انتخاب گیرنده" }}
            </h5>
          </v-col>
          <v-col class="text-left">
            <div class="blue-input">
              <v-text-field
                class="float-left"
                v-model="search"
                outlined
                dense
                placeholder="جستجو"
              >
                <template #prepend-inner>
                  <v-icon> $Magnify </v-icon>
                </template>
                <template v-if="search.length" #append>
                  <v-icon @click="search = ''"> $Close </v-icon>
                </template>
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <!--<v-col class="text-left">
            <v-btn
              @click="
                addEmployeeDialog = true;
                newEmployee = {};
              "
              :class="
                type == 'inbox'
                  ? 'purple-borderedBtn'
                  : type == 'outbox'
                  ? 'green-borderedBtn'
                  : 'blue-borderedBtn'
              "
            >
              <v-icon
                class="px-1"
                :class="
                  type == 'inbox'
                    ? 'purple-icon'
                    : type == 'outbox'
                    ? 'green-icon'
                    : 'blue-icon'
                "
                >$AddSquare</v-icon
              >
              عضو جدید
            </v-btn>
          </v-col>-->
        </v-row>

        <div v-if="!computedUsers.length && search" class="text-center h3 mt-6">
          نتیجه‌ای برای "{{ search }}" یافت نشد.
        </div>
        <div v-else class="users mt-1">
          <div v-if="userType == 'sender'" class="px-3">
            <div v-if="employeesLoading" class="text-center">
              <v-progress-circular indeterminate color="#7e57c2">
              </v-progress-circular>
              <div color="#7e57c2" class="mt-4">در حال بارگذاری...</div>
            </div>
            <div v-else>
              <div class="mt-5">
                <b-table
                  responsive
                  show-empty
                  :current-page="currentPageEmployee"
                  :per-page="perPageEmployee"
                  :items="computedUsers"
                  :fields="employeesFields"
                  empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  striped
                  thead-class="headClass"
                  tbody-class="blue-trClass
                  "
                  :busy="isBusy2"
                >
                  <template v-slot:cell(index)="data">
                    <div
                      style="text-align: center; vertical-align: middle"
                      class="pt-0"
                    >
                      {{
                        data.index +
                        1 +
                        perPageEmployee * (currentPageEmployee - 1)
                      }}
                    </div>
                  </template>
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div
                      style="text-align: center; vertical-align: middle"
                      class="pt-0 grey-color"
                    >
                      <div class="mt-2">{{ data.value }}</div>
                    </div>
                  </template>
                  <template v-slot:cell(name)="data">
                    <div
                      style="text-align: center; vertical-align: middle"
                      class="pt-0 grey-color"
                    >
                      <div class="mt-2">
                        {{ data.item.name }}
                      </div>
                    </div>
                  </template>

                  <template v-slot:cell(operation)="data">
                    <div style="text-align: center; vertical-align: middle">
                      <v-btn
                        v-if="selectedSender != data.item"
                        @click="selectUser(data.item)"
                        height="40"
                        width="80"
                        class="blue-mainBtn pa-3"
                        >انتخاب</v-btn
                      >
                      <v-btn
                        v-if="selectedSender == data.item"
                        @click="selectedSender = ''"
                        height="40"
                        class="red-mainBtn pa-3"
                        width="80"
                        >حذف</v-btn
                      >
                    </div>
                  </template>
                  <div slot="table-busy" class="text-center text-danger my-2">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      class="align-middle"
                    ></v-progress-circular>
                  </div>
                </b-table>
                <div
                  class="d-flex flex-row justify-content-end blue-custom-pagination"
                >
                  <v-btn @click="goToFirstEmployee()" class="navigation-btn"
                    ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                  >
                  <v-pagination
                    v-model="currentPageEmployee"
                    :length="Math.ceil(computedUsers.length / perPageEmployee)"
                    :total-visible="5"
                    prev-icon="$ArrowLeft"
                    next-icon="$ArrowRight"
                  ></v-pagination>
                  <v-btn @click="goToLastEmployee()" class="navigation-btn"
                    ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="userType == 'receiver'" class="px-3">
            <div>
              <b-table
                responsive
                show-empty
                stacked="sm"
                :current-page="currentPageEmployee"
                :per-page="perPageEmployee"
                :items="computedUsers"
                :fields="employeesFields"
                empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                empty-text="رکوردی برای نمایش وجود ندارد"
                striped
                thead-class="headClass"
                tbody-class="blue-trClass
                "
                :busy="isBusy2"
              >
                <template v-slot:cell(index)="data">
                  <div
                    style="text-align: center; vertical-align: middle"
                    class="pt-0"
                  >
                    {{
                      data.index +
                      1 +
                      perPageEmployee * (currentPageEmployee - 1)
                    }}
                  </div>
                </template>
                <template v-slot:head()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div
                    style="text-align: center; vertical-align: middle"
                    class="pt-0 grey-color"
                  >
                    <div class="mt-2">{{ data.value }}</div>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  <div
                    style="text-align: center; vertical-align: middle"
                    class="pt-0 grey-color"
                  >
                    <div class="mt-2">
                      {{ data.item.name }}
                    </div>
                  </div>
                </template>

                <template v-slot:cell(operation)="data">
                  <div style="text-align: center; vertical-align: middle">
                    <v-btn
                      v-if="!selectedReceivers.includes(data.item)"
                      @click="selectUsers(data.item)"
                      height="40"
                      width="80"
                      class="blue-mainBtn pa-3"
                      >انتخاب</v-btn
                    >
                    <v-btn
                      v-if="selectedReceivers.includes(data.item)"
                      @click="removeUser(data.item)"
                      height="40"
                      class="red-mainBtn pa-3"
                      width="80"
                      >حذف</v-btn
                    >
                  </div>
                </template>
                <div slot="table-busy" class="text-center text-danger my-2">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="align-middle"
                  ></v-progress-circular>
                </div>
              </b-table>
              <div
                class="d-flex flex-row justify-content-end blue-custom-pagination"
              >
                <v-btn @click="goToFirstEmployee()" class="navigation-btn"
                  ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                >
                <v-pagination
                  v-model="currentPageEmployee"
                  :length="Math.ceil(computedUsers.length / perPageEmployee)"
                  :total-visible="5"
                  prev-icon="$ArrowLeft"
                  next-icon="$ArrowRight"
                ></v-pagination>
                <v-btn @click="goToLastEmployee()" class="navigation-btn"
                  ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                >
              </div>
            </div>
          </div>
        </div>

        <v-card-actions class="px-0">
          <v-btn
            @click="submitUser()"
            width="130"
            height="40"
            class="mt-6 float-start blue-mainBtn"
            v-if="
              userType == 'sender' ? selectedSender : selectedReceivers.length
            "
            >تایید</v-btn
          >

          <v-spacer></v-spacer>
          <v-btn
            @click="choosePersonDialog = false"
            width="130"
            height="40"
            class="red-mainBtn mt-6"
            >بازگشت</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="chooseTagDialog" width="600">
      <v-card class="dash-modal-card">
        <v-row>
          <v-col class="pt-4">
            <h5 class="mt-1 grey-color">افزودن تگ دلخواه</h5>
          </v-col>
        </v-row>
        <div v-if="letterDetails.tags">
          <div v-if="letterDetails.tags.length">
            <v-row>
              <v-col class="pt-4">
                <h5 class="mt-1 grey-color">تگ‌های پیشین</h5>
              </v-col>
              <v-col class="text-left">
                <div class="blue-input">
                  <v-text-field
                    class="float-left"
                    v-model="searchTag"
                    outlined
                    dense
                    placeholder="جستجو در تگ‌ها"
                  >
                    <template #prepend-inner>
                      <v-icon> $Magnify </v-icon>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
            </v-row>

            <div class="d-flex border pa-3 mt-2 rounded-lg">
              <div
                v-if="!computedTags.length"
                class="text-center w-100 h6 grey-color pa-3"
              >
                <span> نتیجه‌ای برای "{{ searchTag }}" یافت نشد. </span>
              </div>
              <div
                v-else
                class="blue-badge2 ms-1"
                v-for="(item, index) in computedTags"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
        </div>

        <v-row class="mt-2">
          <v-col>
            <h5 class="mt-1 grey-color">افزودن تگ جدید</h5>
            <div class="blue-input">
              <div class="d-flex flex-row">
                <v-text-field
                  outlined
                  dense
                  height="40"
                  class="mt-1"
                  v-model="newTag"
                  @keyup.enter="newTag ? addNewTag() : ''"
                >
                  <template slot="label">
                    <div style="font-size: 14px">تگ جدید</div>
                  </template>
                </v-text-field>
                <v-btn
                  class="ms-2 mt-1 blue-mainBtn"
                  width="60"
                  min-width="40"
                  height="40"
                  :disabled="!newTag"
                  @click="addNewTag()"
                  >ثبت</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
        <div v-if="newTagList">
          <v-row v-if="newTagList.length">
            <v-col class="d-flex flex-wrap">
              <div
                class="blue-badge2 me-2 mb-2"
                v-for="(item, index) in newTagList"
                :key="index"
              >
                <span>{{ item }}</span>
                <span
                  ><v-icon
                    @click="removeTag('newTagList', index)"
                    color="#515BA1"
                    small
                    class="ps-1"
                    >mdi-close</v-icon
                  ></span
                >
              </div>
            </v-col>
          </v-row>
        </div>

        <v-card-actions class="px-0">
          <v-btn
            @click="submitTag()"
            width="130"
            height="40"
            class="mt-6 float-start blue-mainBtn"
            v-if="newTagList.length > 0"
            >تایید</v-btn
          >

          <v-spacer></v-spacer>
          <v-btn
            @click="
              chooseTagDialog = false;
              newTagList = [];
            "
            width="130"
            height="40"
            class="red-mainBtn mt-6"
            >بازگشت</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- addLetterDialog -->
    <RelatedLettersModal
      v-if="addLetterDialog"
      :addLetterDialog="addLetterDialog"
      @addLetters="addLetters"
      :contacts="contacts"
      :customEmployees="customEmployees"
      :signatories="signatories"
      :selectEmployees="selectEmployees"
      @closeAddLetterDialog="closeAddLetterDialog"
    />

    <v-dialog v-model="showBig">
      <v-card class="modal-card">
        <div class="image-box">
          <img :src="selectedFile" alt="" style="height: 100%" />
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showBigUrl">
      <v-card class="modal-card">
        <div class="image-box">
          <img :src="selectedUrl" alt="" style="height: 100%" />
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="letterResultDialog" width="700">
      <v-card
        class="dash-modal-card px-5"
        style="overflow-y: auto !important; overflow-x: hidden"
      >
        <span v-show="false">{{ reactivity2 }}</span>
        <v-row>
          <v-col>
            <!-- sender and receiver part for new -->
            <div>
              <div class="mt-2">
                <v-icon class="pe-3 h5">$EditUser</v-icon>
                <span class="grey-color h5">فرستنده: </span>
                <span class="h5 font-weight-bold dark-grey-color">
                  {{
                    letterDetails.sender ? letterDetails.sender.name : "-"
                  }}</span
                >
              </div>

              <div class="mt-2">
                <v-icon class="pe-3 h5">$TickUser</v-icon>
                <span class="grey-color h5">گیرنده: </span>
                <span
                  class="h5 font-weight-bold dark-grey-color"
                  v-if="letterDetails.selectedReceivers.length == 1"
                >
                  {{ letterDetails.selectedReceivers[0].name }}
                </span>

                <v-tooltip v-else max-width="300" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="h5 font-weight-bold dark-grey-color"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ letterDetails.selectedReceivers[0].name }}
                      و
                      {{ letterDetails.selectedReceivers.length - 1 }}
                      نفر دیگر
                    </span>
                  </template>
                  <span class="fs-xsmall">
                    {{
                      letterDetails.selectedReceivers
                        .map((x) => x.name)
                        .join(" , ")
                    }}
                  </span>
                </v-tooltip>
              </div>
            </div>

            <div class="my-2">
              <v-icon class="pe-3 h5">mdi-arrow-left-circle-outline</v-icon>
              <span class="grey-color h5">تخصیص به:</span>
              <span class="grey-color h5">
                {{ computedAssigne ? computedAssigne : "-" }}
              </span>
            </div>
            <div class="my-3">
              <span class="grey-color">تاریخ ارسال:</span>
              <span class="grey-color">
                {{ letterDetails.date ? letterDetails.date : "-" }}
              </span>
            </div>
            <div class="my-3">
              <span class="grey-color">تاریخ پیگیری نامه:</span>
              <span class="grey-color">
                {{
                  letterDetails.followingDate
                    ? letterDetails.followingDate
                    : "-"
                }}
              </span>
            </div>
            <div v-if="allCodes.length && letterDetails.orderId">
              <span class="grey-color"
                >استعلام مرتبط با نامه:
                <span class="grey-color">{{
                  allCodes.filter((x) => x.id == letterDetails.orderId)[0].code
                }}</span></span
              >
            </div>
          </v-col>
          <v-col class="text-end">
            <v-btn class="red-mainBtn ms-2" @click="letterResultDialog = false"
              >بازگشت</v-btn
            >
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col>
            <v-row>
              <v-col class="d-flex">
                <div class="mt-2">
                  <span class="grey-color"> موضوع نامه:</span>
                  <span class="grey-color">
                    {{ letterDetails.title ? letterDetails.title : "-" }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div
                  class="grey-color px-3 border-box"
                  style="text-align: justify; line-height: 30px"
                  v-html="letterDetails.text ? letterDetails.text : '-'"
                ></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <hr v-if="letterDetails.files.length" />
        <v-row v-if="letterDetails.files.length">
          <v-col>
            <h5 class="grey-color">
              تعداد فایل‌های پیوست: {{ letterDetails.files.length }} عدد
            </h5>
            <div class="d-flex flex-row flex-wrap mt-5">
              <div
                class="file-box mx-3 mb-6"
                v-for="(file, index) in letterDetails.files"
                :key="index"
              >
                <div>
                  <v-img
                    :src="chooseFileImageType(file.url, file.type)"
                    style="border-radius: 12px 12px 0 0"
                  ></v-img>
                </div>
                <div class="file-info grey-color pa-4">
                  <span>{{
                    file.name.length > 25
                      ? file.name.slice(0, 25) + "..."
                      : file.name
                  }}</span>
                  <div class="mt-5 d-flex flex-row justify-space-between">
                    <div>
                      <span dir="ltr">{{ file.size }}</span>
                    </div>
                    <div>
                      <span
                        @click="showFile(file.url)"
                        class="mx-4 pointer"
                        v-if="
                          file.type == 'image/png' || file.type == 'image/jpeg'
                        "
                        ><v-icon class="blue-icon">$View</v-icon></span
                      >
                      <span
                        @click="download(file.url, file.name)"
                        class="pointer"
                        ><v-icon class="blue-icon">$Download</v-icon></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <hr v-if="letterDetails.tags.length" />
        <v-row v-if="letterDetails.tags.length" class="tag-area me-1">
          <v-col class="d-flex flex-wrap">
            <div
              v-for="(item, index) in letterDetails.tags"
              :key="index"
              class="me-3 blue-badge2"
            >
              <span> {{ item }} </span>
            </div></v-col
          >
        </v-row>
        <!--<v-row>
          <v-col>
            <v-btn class="blue-borderedBtn" @click="letterResultDialog = false">
              <v-icon class="blue-icon pe-2">$Pen</v-icon>
              ویرایش
            </v-btn>
          </v-col>
          <v-col class="text-left">
            <v-btn
              class="purple-borderedBtn"
              @click="
                action == 'edit' ? editLetter('draft') : submitLetter('draft')
              "
              >ذخیره در پیش‌نویس</v-btn
            >
          </v-col>
        </v-row>-->
      </v-card>
    </v-dialog>
    <v-dialog v-model="comingSoon" width="400">
      <v-card class="modal-card">
        <div class="text-center border-box pb-4 px-4">
          این ویژگی به زودی فعال می‌شود.
          <div class="text-center">
            <v-btn class="mt-6 blue-borderedBtn" @click="comingSoon = false"
              >فهمیدم!</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import VueCkeditor from "vue-ckeditor2";
import moment from "moment-jalaali";
import Axios from "axios";
import RelatedLettersModal from "@/components/letters/RelatedLettersModal";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    VueCkeditor,
    RelatedLettersModal,
  },
  data() {
    return {
      farsiType: "سازمانی",
      currentDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      reactivity: false,
      reactivity2: false,
      templateLoading: false,
      selected: {},
      selectedReceivers: [],
      selectedReceiverIds: [],
      letterDetails: {
        sender: "",
        receiver: "",
        date: moment(new Date()).format("jYYYY/jMM/jDD"),
        title: "",
        tags: [],
        relatedLetters: [],
        relatedLetterIds: [],
        inboxAnswer: "",
        outboxAnswer: "",
        followingDate: "",
        letterFileId: "",
        letterNumber: "",
        project: "",
        followingLetter: false,
        followingLetterNo: "",
        isResponse: false,
        responseArchiveNo: "",
        files: [],
        fileIds: [],
        letterFile: "",
        letterFileName: "",
        letterFileType: "",
        letterFileSize: "",
        selectedReceivers: [],
        selectedReceiverIds: [],
      },
      config: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "TextColor",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "Smiley",
            "Font",
            "FontSize",
            "Styles",
          ],
        ],
      },
      choosePersonDialog: false,
      chooseProjectDialog: false,
      chooseTagDialog: false,
      addLetterDialog: false,
      letterResultDialog: false,
      //addEmployeeDialog: false,
      newProjectDialog: false,
      comingSoon: false,
      search: "",
      searchProject: "",
      searchTag: "",
      selectedSender: "",
      selectedReceiver: "",
      selectedProject: "",
      userType: "",
      templates: [],
      allCodes: [],
      gettingCodes: false,
      newTag: "",
      newTagList: [],
      currentPage: 1,
      perPage: 4,
      isBusy: false,
      isBusy2: false,
      currentPageContact: 1,
      perPageContact: 4,
      currentPageEmployee: 1,
      perPageEmployee: 4,
      currentPageLetter: 1,
      perPageLetter: 10,
      filter: {
        type: "",
        sender: "",
        receiver: "",
        letterNo: "",
        title: "",
        tags: [],
        startDate: moment(new Date())
          .subtract(7, "day")
          .format("jYYYY/jMM/jDD"),
        endDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      },
      relatedLetters: [],
      letterTypes: [
        { value: "internal", text: "سازمانی" },
        { value: "inbox", text: "ورودی" },
        { value: "output", text: "خروجی" },
      ],
      fields: [
        { key: "index", label: "#" },
        { key: "letterNo", label: "شماره نامه" },
        { key: "title", label: "موضوع نامه" },
        { key: "date", label: "تاریخ ارسال" },
        { key: "operation", label: "عملیات" },
      ],
      contacts: [],
      contactsFields: [
        // { key: "index", label: "#" },
        { key: "choiceName", label: "نام" },
        { key: "operation", label: "انتخاب" },
      ],
      employeesFields: [
        // { key: "index", label: "#" },
        { key: "name", label: "نام" },
        { key: "operation", label: "انتخاب" },
      ],
      uploadedFiles: [],
      uploadPercentage: 0,
      isUploaded: false,
      file: [],
      myContextFile: new Object({
        uploadPercentage: 0,
      }),
      contextUploading: false,
      fileUploading: false,
      contextFileName: "",
      myFile: new Object({
        uploadPercentage: 0,
      }),
      showName: "",
      selectedFile: {},
      selectedUrl: "",
      showBig: false,
      showBigUrl: false,
      farsiType: "",
      replyLetterNo: "",
      employees: [],
      selectEmployees: [],
      newEmployee: {},
      projects: [],
      newProject: {},
      contactsLoading: false,
      employeesLoading: false,
      letterId: "",
      typeName: "",
      letterFileType: "",
      typeList: [
        { key: "output", text: "outbox" },
        { key: "internal", text: "internal" },
        { key: "arrival", text: "inbox" },
      ],
      customEmployees: [],
      tagName: "",
      relatedLetters: [],
      relatedLetterIds: [],
      loading: false,
      allCodes: [],
      role: "",
      signatoriesLoading: false,
      signatories: [],
    };
  },
  computed: {
    computedUsers() {
      let filtered;
      filtered = this.employees.filter((x) => {
        return x.name.includes(this.search);
      });
      this.currentPageEmployee = 1;
      return filtered;
    },
    computedTags() {
      let filtered;
      filtered = this.letterDetails.tags.filter((x) =>
        x.includes(this.searchTag)
      );
      return filtered;
    },
    computedAssigne() {
      let choosenAssigne;
      this.employees
        .filter((x) => x.id == this.letterDetails.assigneId)
        .map((x) => {
          choosenAssigne = x.name;
        });
      return choosenAssigne;
    },
    computedSenderReceiver() {
      if (
        !this.letterDetails.sender ||
        this.letterDetails.selectedReceiverIds.length == 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getSignatories() {
      this.signatoriesLoading = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/contacts/signatories",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.signatories = res.data.map((x) => {
            return {
              id: x.id,
              choiceName: x.name,
            };
          });
          this.signatoriesLoading = false;
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
          this.signatoriesLoading = false;
        });
    },
    closeAddLetterDialog(val) {
      this.addLetterDialog = val;
    },
    goToLetterInfo() {
      this.$router.push(
        "/" +
          this.role +
          "/secretariat/internal/letterInfo/" +
          this.$route.params.id
      );
    },
    getOrderId(value) {
      this.letterDetails.orderId = value;
    },
    getOrdersCode(value) {
      this.allCodes = value;
    },
    selectUsers(item) {
      this.selectedReceivers.push(item);
      this.selectedReceiverIds.push(item.id);
    },
    removeUser(item) {
      let index = this.selectedReceivers.findIndex((x) => {
        return item.id == x.id;
      });
      if (index > -1) {
        this.selectedReceivers.splice(index, 1);
        this.selectedReceiverIds.splice(index, 1);
      }
    },
    getEmployees() {
      this.employeesLoading = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/contacts/internals",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.employees = res.data;
          this.employeesLoading = false;
          this.selectEmployees = res.data.map((x) => {
            return {
              id: x.id,
              text: x.name,
            };
          });
          this.customEmployees = res.data.map((x) => {
            return {
              id: x.id,
              choiceName: x.name,
            };
          });
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    getContacts() {
      this.contactsLoading = true;

      Axios.post(
        this.baseUrl + "/clinic/secretariat/contacts/externals",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.contacts = res.data.users;
          setTimeout(() => {
            this.contactsLoading = false;
          }, 500);
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    removeTag(index) {
      this.filter.tags.splice(index, 1);
    },
    getTemplateInfo(templateId) {
      this.templateLoading = true;
      this.templateId = templateId;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/templateLetter/getInfo",
        {
          templateId: this.templateId,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.letterDetails.text = res.data.text;
          this.templateLoading = false;
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
          this.templateLoading = false;
        });
    },
    addLetters(item) {
      if (!this.letterDetails.relatedLetterIds.includes(item.id)) {
        this.selected = item;
        this.letterDetails.relatedLetterIds.push(item.id);
        this.letterDetails.relatedLetters.push(item);
        this.toast("نامه انتخابی افزوده شد.", "success");
      } else {
        this.toast(".این نامه پیش از این افزوده شده است", "error");

        return;
      }
    },
    removeLetters(index) {
      if ((index + 1) % 10 == 0) {
        this.currentPageLetter--;
      }
      this.letterDetails.relatedLetters.splice(index, 1);
      this.letterDetails.relatedLetterIds = [];
      this.letterDetails.relatedLetters.forEach((x) => {
        this.letterDetails.relatedLetterIds.push(x.id);
      });
    },
    getTemplates() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/templateLetters/byUser",
        {
          type: "internal",
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.templates = res.data;
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },

    goToLetter() {
      this.$router.push(
        "/" +
          this.role +
          "/secretariat/internal/letterInfo/" +
          this.$route.params.id
      );
    },
    submitLetter(status) {
      let endpoint = "";
      status == "draft"
        ? (endpoint = "/clinic/secretariat/letter/draft")
        : (endpoint = "/clinic/secretariat/letter/new");
      this.vLoading = true;
      Axios.post(
        this.baseUrl + endpoint,
        {
          title: this.letterDetails.title,
          date: this.letterDetails.date,
          type: "internal",
          senderId: this.letterDetails.sender.id,
          receiverId: this.letterDetails.selectedReceiverIds,
          assigneId: this.letterDetails.assigneId,
          orderId: this.letterDetails.orderId,
          fileIds: this.letterDetails.fileIds,
          text: this.letterDetails.text,
          followingDate: this.letterDetails.followingDate,
          tags: this.letterDetails.tags,
          templateId: this.letterDetails.templateId,
          relatedLetterIds: this.letterDetails.relatedLetterIds,
          letterFileId: this.letterDetails.letterFileId,
          isResponse: this.letterDetails.isResponse,
          responseArchiveNo: this.letterDetails.isResponse
            ? this.letterDetails.responseArchiveNo
            : "",
          followingLetter: this.letterDetails.followingLetter,
          followingLetterNo: this.letterDetails.followingLetter
            ? this.letterDetails.followingLetterNo
            : "",
          status: status,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (status == "draft") {
            this.toast("پیش‌نویس با موفقیت ثبت شد.", "success");
            this.$router.push(
              "/" +
                this.role +
                "/secretariat/drafts/completeDraft/" +
                (Array.isArray(res.data) ? res.data[0] : res.data)
            );
            this.vLoading = false;
          } else {
            this.$router.push(
              "/" +
                this.role +
                "/secretariat/internal/letterInfo/" +
                (Array.isArray(res.data) ? res.data[0] : res.data)
            );
          }
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
          this.vLoading = false;
        });
    },
    download(fileUrl, fileName) {
      var a = document.createElement("a");
      a.setAttribute("href", fileUrl);
      a.setAttribute("download", fileName);
      a.setAttribute("target", "blank");
      a.click();
    },
    openSelectUserDialog(type) {
      this.userType = type;
      this.choosePersonDialog = true;
      this.currentPageContact = 1;
      this.currentPageEmployee = 1;
      this.search = "";
    },
    selectUser(item) {
      this.userType == "sender"
        ? (this.selectedSender = item)
        : (this.selectedReceiver = item);
    },
    submitUser() {
      if (this.userType == "receiver") {
        this.letterDetails.selectedReceivers = JSON.parse(
          JSON.stringify(this.selectedReceivers)
        );
        this.letterDetails.selectedReceiverIds = JSON.parse(
          JSON.stringify(this.selectedReceiverIds)
        );
        this.choosePersonDialog = false;
      } else {
        this.letterDetails.sender = this.selectedSender;
        this.choosePersonDialog = false;
      }
    },
    selectProject(item) {
      this.selectedProject = item;
    },
    submitProject() {
      this.letterDetails.project = this.selectedProject;
      this.chooseProjectDialog = false;
    },
    addNewTag() {
      if (!this.newTagList.includes(this.newTag.trim())) {
        this.newTagList.push(this.newTag.trim());
        this.newTag = "";
      } else {
        this.$toast("این تگ پیش از این افزوده شده است.", "error");
      }
    },
    removeTag(array, index) {
      if (array == "newTagList") {
        this[array].splice(index, 1);
      } else {
        this[array].tags.splice(index, 1);
      }
    },
    addTag() {
      if (!this.filter.tags.includes(this.tagName.trim())) {
        this.filter.tags.push(this.tagName.trim());
        this.tagName = "";
      } else {
        this.$toast("این تگ پیش از این افزوده شده است.", "error");
      }
    },
    removeRelatedTag(index) {
      this.filter.tags.splice(index, 1);
    },
    submitTag() {
      this.letterDetails.tags.push.apply(
        this.letterDetails.tags,
        this.newTagList
      );
      this.letterDetails.tags = this.letterDetails.tags.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      this.chooseTagDialog = false;
      this.newTagList = [];
      this.newTag = "";
    },
    goToFirst() {
      this.currentPage = 1;
    },
    goToLast() {
      this.currentPage = Math.ceil(this.relatedLetters.length / this.perPage);
    },
    goToFirstContact() {
      this.currentPageContact = 1;
    },
    goToLastContact() {
      this.currentPageContact = Math.ceil(
        this.computedContacts.length / this.perPageContact
      );
    },
    goToFirstEmployee() {
      this.currentPageEmployee = 1;
    },
    goToLastEmployee() {
      this.currentPageEmployee = Math.ceil(
        this.computedUsers.length / this.perPageEmployee
      );
    },
    goToFirstLetter() {
      this.currentPageLetter = 1;
    },
    goToLastLetter() {
      this.currentPageLetter = Math.ceil(
        this.letterDetails.relatedLetters.length / this.perPageLetter
      );
    },
    uploadContext(file) {
      if (!file) {
        return;
      }
      let sizeMB = file.size / 1024 / 1024;

      if (sizeMB > 5) {
        this.toast(
          "حجم فایل " + file.name + " نباید از 5 مگابایت بیشتر باشد",
          "error"
        );
        return;
      } else {
        //CHECK FILE SIZE
        this.contextUploading = true;

        // START UPLOAD PROCESS
        this.myContextFile.showProgress = true;
        this.myContextFile.variant = "info";
        this.myContextFile.uploadPercentage = 0;
        this.myContextFile.isUploaded = false;
        this.myContextFile.uploadButDisable = true;
        var formData = new FormData();
        formData.append(file.name, file);

        this.contextFileName = file.name;
        Axios.post(
          this.baseUrl + "/clinic/secretariat/letter/upload",
          formData,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.myContextFile.uploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          }
        )
          .then((res) => {
            if (res.status == 201) {
              this.myContextFile.isUploaded = true;
              this.myContextFile.uploadPercentage = 100;
              this.myContextFile.striped = false;
              this.myContextFile.variant = "success";
              this.myContextFile.uploadButDisable = false;
              this.letterDetails.letterFileId = res.data.id;
              this.letterDetails.letterFileName = res.data.name;
              this.letterDetails.letterFile = res.data.url;
              this.letterDetails.letterFileSize = res.data.size;
              this.letterDetails.letterFileType = res.data.type;

              this.contextUploading = false;
            }
          })
          .catch((err) => {
            this.toast(err.response.data, "error");
            this.myContextFile.uploadPercentage = 0;
          });
      }
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      // if (!event.currentTarget.classList.contains("bg-green-300")) {
      //   event.currentTarget.classList.remove("bg-gray-100");
      //   event.currentTarget.classList.add("bg-green-300");
      // }
    },
    dragleave(event) {
      // Clean up
      // event.currentTarget.classList.add("bg-gray-100");
      // event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      //event.preventDefault();
      //this.file = event.dataTransfer.files;
      //this.myUpload(this.file);
      // Clean up
      // event.currentTarget.classList.add("bg-gray-100");
      // event.currentTarget.classList.remove("bg-green-300");
      event.preventDefault();
      this.file = event.dataTransfer.files;
      if (Array.from(this.file).length) {
        Array.from(this.file).forEach((file) => {
          this.uploadContext(file);
        });
      }
    },
    removeContext() {
      this.letterDetails.letterFileId = "";
      this.letterDetails.letterFile = "";
      this.letterDetails.letterFileName = "";
      this.letterDetails.letterFileType = "";
      this.contextUploading = false;
    },
    removeFile(index) {
      if (this.letterDetails.files.length > 1) {
        if (this.letterDetails.fileIds) {
          this.letterDetails.fileIds.splice(index, 1);
        }
        this.letterDetails.files.splice(index, 1);
      } else if (this.letterDetails.files.length == 1) {
        this.letterDetails.fileIds = [];
        this.letterDetails.files = [];
      }
    },
    showFile(file) {
      this.selectedFile = file;
      this.showBig = true;
    },
    showFileViaUrl(url) {
      this.selectedUrl = url;
      this.showBigUrl = true;
    },
    uploadFile(file) {
      if (this.letterDetails.files.length == 0) {
        this.letterDetails.fileIds = [];
        this.letterDetails.files = [];
      }
      if (!file) {
        return;
      }
      let sizeMB = file.size / 1024 / 1024;

      if (sizeMB > 5) {
        this.toast(
          "حجم فایل " + file.name + " نباید از 5 مگابایت بیشتر باشد",
          "error"
        );
        return;
      } else {
        //CHECK FILE SIZE
        this.fileUploading = true;

        // START UPLOAD PROCESS
        this.myFile.showProgress = true;
        this.myFile.variant = "info";
        this.myFile.uploadPercentage = 0;
        this.myFile.isUploaded = false;
        this.myFile.uploadButDisable = true;
        var formData = new FormData();
        formData.append(file.name, file);
        this.showName = file.name;
        Axios.post(
          this.baseUrl + "/clinic/secretariat/letter/upload",
          formData,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.myFile.uploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          }
        )
          .then((res) => {
            if (res.status == 201) {
              this.myFile.isUploaded = true;
              this.myFile.uploadPercentage = 100;
              this.myFile.striped = false;
              this.myFile.variant = "success";
              this.myFile.uploadButDisable = false;
              this.fileUploading = false;
              this.letterDetails.files.push(res.data);
              this.letterDetails.fileIds.push(res.data.id);
            }
          })
          .catch((err) => {
            this.toast(err.response.data, "error");
            this.myFile.uploadPercentage = 0;
          });
      }
    },
    dropFile(event) {
      //event.preventDefault();
      //this.file = event.dataTransfer.files;
      //this.myUpload(this.file);
      // Clean up
      // event.currentTarget.classList.add("bg-gray-100");
      // event.currentTarget.classList.remove("bg-green-300");
      event.preventDefault();
      this.file = event.dataTransfer.files;
      if (Array.from(this.file).length) {
        Array.from(this.file).forEach((file) => {
          this.uploadFile(file);
        });
      }
    },
    chooseFileImageType(url, type) {
      if (type == "image/jpeg" || type == "image/png") {
        return url;
      } else if (type == "application/pdf") {
        return "https://apksshare.com/wp-content/uploads/2021/06/Image-to-PDF-Converter-Free-JPG-to-PDF-APK-MOD-Download-2.3.4.png";
      } else if (
        type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvCi0I-vSsvxIKLa9BKrRCJeH4bBXKwkqNUMz-60tYH8gHZfTUFxK6lkLqoXoMaaLj2dE&usqp=CAU";
      } else {
        return "https://i.pinimg.com/originals/c4/8e/f0/c48ef06a13a33833e9f766b2c191329e.png";
      }
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.getTemplates();
    this.getEmployees();
    this.getContacts();
    this.getSignatories();
  },
};
</script>
<style lang="scss">
* {
  font-family: iransans;
}
@font-face {
  font-family: iransans;
  src: url("../../../assets/fonts/IRANSans(FaNum).ttf");
}
.new-letter {
  .letter-card {
    border-radius: 17px;
    padding: 20px 20px 20px 25px;
    margin-top: -10px;
    .custom-input-purple {
      input {
        padding-right: 10px !important;
      }
      .v-text-field__details {
        display: none !important;
      }
      .v-text-field .v-input__control,
      .v-text-field fieldset {
        border-radius: 12px !important;
        border-color: #7e57c2 !important;
      }
      .v-label {
        color: #7e57c2 !important;
      }
      .v-icon {
        path {
          stroke: #7e57c2 !important;
        }
      }
    }
    .custom-input-green {
      input {
        padding-right: 10px !important;
      }
      .v-text-field__details {
        display: none !important;
      }
      .v-text-field .v-input__control,
      .v-text-field fieldset {
        border-radius: 12px !important;
        border-color: #00acc1 !important;
      }
      .v-label {
        color: #00acc1 !important;
      }
      .v-icon {
        path {
          stroke: #00acc1 !important;
        }
      }
    }
    .custom-input-blue {
      input {
        padding-right: 10px !important;
      }
      .v-text-field__details {
        display: none !important;
      }
      .v-text-field .v-input__control,
      .v-text-field fieldset {
        border-radius: 12px !important;
        border-color: #4c81c9 !important;
      }
      .v-label {
        color: #4c81c9 !important;
      }
      .v-icon {
        path {
          stroke: #4c81c9 !important;
        }
      }
    }
  }
  .purple-icon px-1 {
    path {
      stroke: #7e57c2 !important;
    }
  }
  .blue-icon {
    path {
      stroke: #6092cd !important;
    }
  }
  .white-icon {
    path {
      stroke: #fff !important;
    }
  }
}
.v-dialog {
  width: fit-content;
}
.modal-card {
  padding: 15px;
  overflow: none;
  border-radius: 12px !important;
  .image-box {
    height: 550px;
    pointer-events: none;
  }
}

.modal-card2 {
  padding: 20px;
  overflow-y: scroll;
  border-radius: 12px !important;
  .borderBox {
    border: 1px solid #a9a9a9;
    border-radius: 12px;
    padding: 15px;
    position: relative;
    min-height: 130px;
    .box-title {
      position: absolute;
      top: -10px;
      right: 15px;
      color: #727171;
      font-size: 13px;
      background-color: #fff;
      padding: 0 10px;
    }
  }
}
.vpd-selected .vpd-day-text {
  color: white !important;
}
.dash-modal-card {
  padding: 20px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  border-radius: 12px !important;

  .image-box {
    width: 60px;
    height: 60px;
    .v-image {
      width: 100%;
      height: 100%;
      border-radius: 50px;
    }
  }
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
    opacity: 0.5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    width: 10px;
    border-radius: 10px;
  }
  .users {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
    border-radius: 4px;
  }
  .purple-select {
    background-color: #f6f1fe;
    span {
      color: #7e57c2;
    }
    border-radius: 12px;
  }
  .purple-hover:hover {
    background-color: #f6f1fe;
    span {
      color: #7e57c2;
    }
    border-radius: 12px;
  }
  .green-select {
    background-color: #ecf9fb;
    span {
      color: #00acc1;
    }
    border-radius: 12px;
  }
  .green-hover:hover {
    background-color: #ecf9fb;
    span {
      color: #00acc1;
    }
    border-radius: 12px;
  }
  .blue-select {
    background-color: #e7f2fe;
    span {
      color: #4c81c9;
    }
    border-radius: 12px;
  }
  .blue-hover:hover {
    background-color: #e7f2fe;
    span {
      color: #4c81c9;
    }
    border-radius: 12px;
  }
}
/* squared-paper-texture 1 */

.purple-input {
  input {
    padding-right: 10px !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  .v-text-field .v-input__control,
  .v-text-field fieldset {
    border-radius: 12px !important;
    border-color: #7e57c2 !important;
  }
  .v-label {
    color: #7e57c2 !important;
  }
}
.green-input {
  input {
    padding-right: 10px !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  .v-text-field .v-input__control,
  .v-text-field fieldset {
    border-radius: 12px !important;
    border-color: #00acc1 !important;
  }
  .v-label {
    color: #00acc1 !important;
  }
}
.blue-input {
  input {
    padding-right: 10px !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  .v-text-field .v-input__control,
  .v-text-field fieldset {
    border-radius: 12px !important;
    border-color: #4c81c9 !important;
  }
  .v-label {
    color: #4c81c9 !important;
  }
}
.file-box {
  width: 250px;
  .v-image {
    background-size: cover;
    height: 190px;
    border-radius: 12px 12px 0 0;
  }
  .file-info {
    background-color: #e7f2fe;
    border-radius: 0 0 12px 12px;
  }
}
.custom-input-purple {
  input {
    padding-right: 10px !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  .v-text-field .v-input__control,
  .v-text-field fieldset {
    border-radius: 12px !important;
    border-color: #7e57c2 !important;
  }
  .v-label {
    color: #7e57c2 !important;
  }
  .v-icon {
    path {
      stroke: #7e57c2 !important;
    }
  }
}
.custom-input-green {
  input {
    padding-right: 10px !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  .v-text-field .v-input__control,
  .v-text-field fieldset {
    border-radius: 12px !important;
    border-color: #00acc1 !important;
  }
  .v-label {
    color: #00acc1 !important;
  }
  .v-icon {
    path {
      stroke: #00acc1 !important;
    }
  }
}
.custom-input-blue {
  input {
    padding-right: 10px !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  .v-text-field .v-input__control,
  .v-text-field fieldset {
    border-radius: 12px !important;
    border-color: #4c81c9 !important;
  }
  .v-label {
    color: #4c81c9 !important;
  }
  .v-icon {
    path {
      stroke: #4c81c9 !important;
    }
  }
}
.modal-card {
  padding: 20px;
  overflow: auto;
  border-radius: 12px !important;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
    opacity: 0.5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    width: 10px;
    border-radius: 10px;
  }
  .image-box {
    height: 550px;
    pointer-events: none;
  }
  .borderBox {
    border: 1px solid #a9a9a9;
    border-radius: 12px;
    padding: 15px;
    position: relative;
    min-height: 130px;
    .box-title {
      position: absolute;
      top: -10px;
      right: 15px;
      color: #727171;
      font-size: 13px;
      background-color: #fff;
      padding: 0 10px;
    }
  }
}
.text-parent {
  position: relative;
  .text-loading {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
